.errorBox {
  position: relative;
  width: 380px;
  max-width: 380px;
  min-height: 50px;
  height: max-content;
  max-height: 80px;
  overflow: auto;
  background-color: #00ddd0;
  border: 1px solid #00ddd0;
  padding: 1rem;
  color: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.errorBox.isActive {
  animation: animateAlertIn 0.25s ease-in;
}

.errorBox > .closeIcon {
  position: absolute;
  top: 13px;
  right: 8px;
  cursor: pointer;
}

.errorText {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorText > svg {
  margin-right: 10px;
}

@keyframes animateAlertIn {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0px);
  }
}
