.card-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
}
.card-wrapper-HC3 {
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}
.card-wrapper-HC3 .card.isLongPressed-true {
  transform: translateX(20px);
}
.button {
  display: flex;
  height: 59px;
  width: 72px;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 6.76px;
  background: #f7f6f3;
  border: none;
  cursor: pointer;
}

.button span {
  font-size: 10px;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  gap: 37px;
}

.card {
  border-radius: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon img {
  height: 30px;
  width: 30px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.details {
  text-align: left;
  align-self: center;
}

.left {
  display: flex;
  justify-content: center;
  text-align: left;
  gap: 15px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.description {
  font-size: 14px;
}
.card-HC1 {
  background-size: cover;
  height: 60px;
}

.card-HC3 {
  background-size: cover;
  height: 350px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: flex-end;
}
.card-HC3 .content .left {
  width: calc(100%);
}
.card-HC3 .content .left .details {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: calc(100% - 60px);
  margin-bottom: 28px;
}
.card-HC3 .content .left .details .title {
  font-weight: 500;
  font-size: 30px;
}

.card-HC3 .content .left .details .description {
  font-size: 12px;
}

.card-HC6 {
  background-size: cover;
  height: 60px;
}

.card-HC9 {
  height: 195px;
}
