.errorStack {
  position: absolute;
  top: 10px;
  right: 10px;
  max-height: 50vh;
  overflow: auto;
  z-index: 2147483647;

  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.errorStack::-webkit-scrollbar {
  display: none;
  width: 0;
}
