.cta {
  display: flex;
  justify-content: center;
  width: 128px;
  height: 42px;
  border-radius: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}
